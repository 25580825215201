import { getAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import {
  CanUploadFieldBinImage,
  DeleteFieldBin
} from '@/store/models/fieldBin';

export default new (class FieldBinAPI {
  public async batchUploadFieldBin(data: FormData) {
    const response = await postAPI(Action.FieldBinBatchUpload, data);
    return response;
  }
  public async updateFieldBinProcessingStatus(id: number) {
    const url = Action.FieldBinBatchUploadProcessingStatus.replace(
      '<id>',
      `${id}`
    );
    const response = await getAPI(url);
    return response;
  }
  public async canUploadFieldBinImage(data: CanUploadFieldBinImage) {
    const response = await postAPI(Action.CanUploadFieldBinImage, data);
    return response;
  }
  public async canUploadMultipleFieldBinImage(data: CanUploadFieldBinImage[]) {
    const response = await postAPI(Action.canUploadMultipleFieldBinImage, data);
    return response;
  }
  public async findBatchDeleteFieldBin(data: DeleteFieldBin) {
    const response = await postAPI(Action.FindBatchDeleteFieldBin, data);
    return response;
  }
  public async batchDeleteFieldBin(data: DeleteFieldBin) {
    const response = await postAPI(Action.BatchDeleteFieldBin, data);
    return response;
  }
  public async downloadFieldBinData(data: FormData) {
    const response = await postAPI(Action.DownloadFieldBinData, data, {
      responseType: 'blob'
    });
    return response;
  }
})();
