import { deleteAPI, getAPI, patchAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import {
  CategoricalEmail,
  DryMatterConfiguration,
  FreeConsultRequest,
  HarvestLoadConfiguration,
  SeasonCalendar,
  SendUserEmail,
  SMTPSetting
} from '@/store/models/configuration';
import { EmailReset } from '@/store/models/jwt';

export default new (class ConfigurationAPI {
  public async getHarvestLoadConfiguration(id: number) {
    const response = await getAPI(`${Action.Farms}/${id}`);
    return response;
  }

  public async updateHarvestLoadConfiguration(
    harvestLoadConfiguration: HarvestLoadConfiguration
  ) {
    const response = await patchAPI(
      `${Action.Farms}/${harvestLoadConfiguration.id}`,
      harvestLoadConfiguration
    );
    return response;
  }

  public async getSMTPSetting(query = '') {
    const response = await getAPI(Action.SMTPSettings, query);
    return response;
  }
  public async updateSMTPSetting(setting: SMTPSetting) {
    const response = await postAPI(Action.SMTPSettings, setting);
    return response;
  }
  public async testSMTPSetting(email: EmailReset) {
    const response = await postAPI(Action.TestSMTPSettings, email);
    return response;
  }

  public async getSeasons(query = '') {
    const response = await getAPI(Action.Season, query);
    return response;
  }

  public async getSeasonCalendars(query = '') {
    const response = await getAPI(Action.SeasonCalendar, query);
    return response;
  }

  public async getSeasonCalendarById(id: number) {
    const response = await getAPI(
      Action.SeasonCalendarDetail.replace('<id>', `${id}`)
    );
    return response;
  }

  public async getLatestSeasonDateByFarm(id: number) {
    const response = await getAPI(
      Action.LatestSeasonDate.replace('<id>', `${id}`)
    );
    return response;
  }

  public async createSeasonCalendar(season: SeasonCalendar) {
    const response = await postAPI(Action.SeasonCalendar, season);
    return response;
  }

  public async updateSeasonCalendar(season: SeasonCalendar, id: number) {
    const response = await patchAPI(
      Action.SeasonCalendarDetail.replace('<id>', `${id}`),
      season
    );
    return response;
  }

  public async deleteSeasonCalendar(id: number) {
    const response = await deleteAPI(
      Action.SeasonCalendarDetail.replace('<id>', `${id}`)
    );
    return response;
  }

  public async sendCategoricalEmail(categoricalEmail: CategoricalEmail) {
    const response = await postAPI(
      Action.SendCategoricalEmail,
      categoricalEmail
    );
    return response;
  }

  public async sendUserEmail(email: SendUserEmail) {
    const response = await postAPI(Action.SendEmail, email);
    return response;
  }

  public async sendFreeConsultRequest(freeConsultRequest: FreeConsultRequest) {
    const response = await postAPI(
      Action.FreeConsultRequest,
      freeConsultRequest
    );
    return response;
  }

  public async getDryMatterConfiguration(id: number) {
    const response = await getAPI(`${Action.Farms}/${id}`);
    return response;
  }

  public async updateDryMatterConfiguration(
    dryMatterConfiguration: DryMatterConfiguration
  ) {
    const response = await patchAPI(
      `${Action.Farms}/${dryMatterConfiguration.id}`,
      dryMatterConfiguration
    );
    return response;
  }
})();
