import { getAPI, patchAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import {
  InviteAdmin,
  InviteConsultant,
  InviteManager,
  InviteMarketer,
  InviteOwner
} from '@/store/models/jwt';
import {
  AssignFarm,
  ChangePassword,
  ChangeRole,
  DeleteInvitedUser,
  ValidateInviteToken
} from '@/store/models/user';
export default new (class UserAPI {
  public async getUserRoles() {
    const response = await getAPI(`${Action.Roles}`);
    return response;
  }
  public async inviteAdmin(email: InviteAdmin[]) {
    const response = await postAPI(Action.InviteAdmin, email);
    return response;
  }
  public async enrollAdmin(user: FormData) {
    const response = await postAPI(Action.RegisterAdmin, user);
    return response;
  }
  public async inviteOwner(email: InviteOwner[]) {
    const response = await postAPI(Action.InviteOwner, email);
    return response;
  }
  public async enrollOwner(user: FormData) {
    const response = await postAPI(Action.RegisterOwner, user);
    return response;
  }
  public async inviteManager(email: InviteManager[]) {
    const response = await postAPI(Action.InviteManager, email);
    return response;
  }
  public async enrollManager(user: FormData) {
    const response = await postAPI(Action.RegisterManager, user);
    return response;
  }
  public async inviteConsultant(email: InviteConsultant[]) {
    const response = await postAPI(Action.InviteConsultant, email);
    return response;
  }
  public async enrollConsultant(user: FormData) {
    const response = await postAPI(Action.RegisterConsultant, user);
    return response;
  }
  public async inviteMarketer(email: InviteMarketer[]) {
    const response = await postAPI(Action.InviteMarketer, email);
    return response;
  }
  public async enrollMarketer(user: FormData) {
    const response = await postAPI(Action.RegisterMarketer, user);
    return response;
  }
  public async validateInviteToken(user: ValidateInviteToken) {
    const response = await postAPI(Action.ValidateInviteToken, user);
    return response;
  }
  public async registerUser(user: FormData) {
    const response = await postAPI(Action.Register, user);
    return response;
  }
  public async checkUsername(username: string) {
    const response = await postAPI(Action.UsernameExists, {
      username: username
    });
    return response;
  }
  public async changePassword(password: ChangePassword) {
    const response = await postAPI(Action.SetPassword, password);
    return response;
  }
  public async getUserDetailById(id: number) {
    const response = await getAPI(`${Action.AdminUsers}/${id}`);
    return response;
  }
  async changeRoleForUser(data: ChangeRole) {
    const response = await postAPI(
      `${Action.AdminUsers}/${data.id}/change_role`,
      data
    );
    return response;
  }
  async assignFarmsForUser(data: AssignFarm) {
    const response = await postAPI(
      `${Action.AdminUsers}/${data.id}/assign_farms`,
      data
    );
    return response;
  }
  public async getAllUsersForAdmin(query = '') {
    const response = await getAPI(Action.AdminUsers, query);
    return response;
  }
  public async getSimpleUserList(query = '') {
    const response = await getAPI(Action.SimpleUserList, query);
    return response;
  }

  public async getInvitations(query = '') {
    const response = await getAPI(`${Action.Invitations}`, query);
    return response;
  }

  public async cancelInvitationById(id: number) {
    const response = await patchAPI(
      `${Action.CancelInvitation.replace('<id>', String(id))}`,
      {}
    );
    return response;
  }

  public async deleteInvitedUser(data: DeleteInvitedUser) {
    const response = await patchAPI(
      `${Action.DeleteAdminUser.replace('<userId>', String(data.userId))}`,
      data
    );
    return response;
  }

  public async activateUser(userId: number) {
    const response = await patchAPI(
      `${Action.ActivateAdminUser.replace('<userId>', String(userId))}`,
      {}
    );
    return response;
  }

  public async deactivateUser(userId: number) {
    const response = await patchAPI(
      `${Action.DeactivateAdminUser.replace('<userId>', String(userId))}`,
      {}
    );
    return response;
  }
})();
