import { deleteAPI, getAPI, postAPI, putAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import {
  BlockSensorHistory,
  Sensor,
  SensorLocation,
  SensorLocationHistory
} from '../models/sensor';

export default new (class SensorAPI {
  public async getSensors(query = '') {
    const response = await getAPI(Action.Sensor, query);
    return response;
  }

  public async createSensor(sensor: Sensor) {
    const response = await postAPI(Action.Sensor, sensor);
    return response;
  }

  public async updateSensor(sensor: Sensor) {
    const response = await putAPI(`${Action.Sensor}/${sensor.id}`, sensor);
    return response;
  }

  public async deleteSensor(id: number) {
    const response = await deleteAPI(`${Action.Sensor}/${id}`);
    return response;
  }

  public async getSensorLocations(query = '') {
    const response = await getAPI(Action.SensorLocation, query);
    return response;
  }

  public async getSensorLocationsWithGeom(query = '') {
    const response = await getAPI(Action.SensorLocation, query);
    return response;
  }

  public async getSensorLocationById(id: number) {
    const response = await getAPI(`${Action.SensorLocation}/${id}`);
    return response;
  }

  public async createSensorLocation(sensor: SensorLocation) {
    const response = await postAPI(Action.SensorLocation, sensor);
    return response;
  }

  public async updateSensorLocation(sensor: SensorLocation) {
    const response = await putAPI(
      `${Action.SensorLocation}/${sensor.id}`,
      sensor
    );
    return response;
  }

  public async deleteSensorLocation(id: number) {
    const response = await deleteAPI(`${Action.SensorLocation}/${id}`);
    return response;
  }

  public async getSensorLocationHistoryBySensorLocationId(
    sensorLocationId: number
  ) {
    const response = await getAPI(
      `${Action.CurrentSensorLocationSensor}/${sensorLocationId}`
    );
    return response;
  }

  public async attachSensorLocation(
    sensorLocationHistory: SensorLocationHistory
  ) {
    const response = await postAPI(
      `${Action.AttachSensorLocationSensor}`,
      sensorLocationHistory
    );
    return response;
  }

  public async detachSensorLocation(sensorLocationId: number) {
    const response = await postAPI(
      `${Action.DetachSensorLocationSensor}/${sensorLocationId}`,
      {}
    );
    return response;
  }

  public async getBlockSensorByBlockId(blockId: number) {
    const response = await getAPI(`${Action.CurrentSensor}/${blockId}`);
    return response;
  }

  public async attachSensor(blockSensor: BlockSensorHistory) {
    const response = await postAPI(`${Action.AttachSensor}`, blockSensor);
    return response;
  }

  public async detachSensor(blockId: number) {
    const response = await postAPI(`${Action.DetachSensor}/${blockId}`, {});
    return response;
  }

  public async downloadSensorDateData(sensorId: number, query = '') {
    const url = Action.SensorDateDataDownload.replaceAll(
      '<sensorId>',
      `${sensorId}`
    );
    const response = await getAPI(url, query);
    return response;
  }

  public async getMetstations(query = '') {
    const response = await getAPI(Action.Metstations, query);
    return response;
  }

  public async downloadMetstationHistory(stationId: number, query = '') {
    const url = Action.MetstationHistoryDownload.replaceAll(
      '<stationId>',
      `${stationId}`
    );
    const response = await getAPI(url, query);
    return response;
  }

  public async getSensorProfileGraphBySensorId(sensorId: number, query = '') {
    const url = Action.SensorProfileGraphBySensorId.replaceAll(
      '<sensorId>',
      `${sensorId}`
    );
    const response = await getAPI(url, query);
    return response;
  }

  public async getMetStationProfileGraphByMetStationId(
    metStationId: number,
    query = ''
  ) {
    const url = Action.MetStationProfileGraphByMetStationId.replaceAll(
      '<metStationId>',
      `${metStationId}`
    );
    const response = await getAPI(url, query);
    return response;
  }

  public async getSensorProfileGraphBySensorLocationId(
    sensorLocationId: number,
    query = ''
  ) {
    const url = Action.SensorProfileGraphBySensorLocationId.replaceAll(
      '<sensorLocationId>',
      `${sensorLocationId}`
    );
    const response = await getAPI(url, query);
    return response;
  }
})();
