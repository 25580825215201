import { getAPI } from '@/store/api';
import { Action } from '@/store/api/actions';

export default new (class MapAPI {
  public async proxyView(url: string) {
    url = url.replaceAll('/api/proxy/', '');
    const response = await getAPI(Action.ProxyView, url);
    return response;
  }
  public async getLayers(query = '') {
    const response = await getAPI(Action.Layers, query);
    return response;
  }
  public async getGroupedLayers(query = '') {
    const response = await getAPI(Action.GroupedLayers, query);
    return response;
  }
  public async getBlockFeature(
    farm: number,
    latitude: number,
    longitude: number
  ) {
    const url = Action.BlockFeatureInfo.replace('<farmId>', `${farm}`)
      .replace('<longitude>', `${longitude}`)
      .replace('<latitude>', `${latitude}`);
    const response = await getAPI(url);
    return response;
  }
  public async getGeoserverConfig() {
    const response = await getAPI(Action.GeoserverConfig);
    return response;
  }
})();
