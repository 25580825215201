import { postAPI } from '@/store/api';
import {
  UserLoginInfo,
  Activation,
  EmailReset,
  ConfirmResetPassword
} from '@/store/models/jwt';
import { Action } from '@/store/api/actions';

export default new (class JWTAPI {
  public async getJWT(user: UserLoginInfo) {
    const response = await postAPI(Action.Token, user, {
      withCredentials: true
    });
    return response;
  }

  public async refreshAccessToken() {
    try {
      const response = await postAPI(
        Action.RefreshToken,
        {},
        {
          withCredentials: true
        }
      );
      return response;
    } catch (error) {
      //pass
    }
  }

  public async clearJWT() {
    const response = await postAPI(
      Action.ClearToken,
      {},
      {
        withCredentials: true
      }
    );
    return response;
  }

  public async userActivation(data: Activation) {
    const response = await postAPI(Action.Activation, data);
    return response;
  }

  public async resendActivation(data: EmailReset) {
    const response = await postAPI(Action.ResendActivation, data);
    return response;
  }

  public async resetPassword(data: EmailReset) {
    const response = await postAPI(Action.ResetPassword, data);
    return response;
  }

  public async confirmResetPassword(data: ConfirmResetPassword) {
    const response = await postAPI(Action.ResetPasswordConfirm, data);
    return response;
  }

  // eslint-disable-next-line
  public async subscribe(data: any) {
    const response = await postAPI(Action.WebPushSubscribe, data);
    return response;
  }
})();
