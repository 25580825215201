import { deleteAPI, getAPI, patchAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';

export default new (class BlockAPI {
  public async getBlogCategories() {
    const response = await getAPI(Action.BlogCategories);
    return response;
  }
  public async getDiscussionSummary() {
    const response = await getAPI(Action.DiscussionSummary);
    return response;
  }
  public async getAllDiscussion(query = '') {
    const response = await getAPI(Action.Discussions, query);
    return response;
  }
  public async getDiscussionDetail(id: number) {
    const response = await getAPI(
      Action.DiscussionDetail.replace('<id>', String(id))
    );
    return response;
  }
  public async closeDiscussion(id: number) {
    const response = await patchAPI(
      Action.CloseDiscussion.replace('<id>', String(id)),
      {}
    );
    return response;
  }
  public async getForums(query: string) {
    const response = await getAPI(Action.Forums, query);
    return response;
  }
  public async getMyBlogs(query = '') {
    const response = await getAPI(Action.MyBlogs, query);
    return response;
  }
  public async getBlogById(id: number) {
    const response = await getAPI(
      Action.BlogDetail.replace('<id>', String(id))
    );
    return response;
  }
  public async createDiscussion(data: FormData) {
    const response = await postAPI(Action.Discussions, data);
    return response;
  }
  public async updateDiscussion(id: number, data: FormData) {
    const response = await patchAPI(
      Action.DiscussionDetail.replace('<id>', String(id)),
      data
    );
    return response;
  }
  public async deleteBlog(id: number) {
    const response = await deleteAPI(
      Action.BlogDetail.replace('<id>', String(id))
    );
    return response;
  }
  public async getForumDetail(id: number) {
    const response = await getAPI(
      Action.ForumDetail.replace('<id>', String(id))
    );
    return response;
  }
  public async updateForum(id: number, data: FormData) {
    const response = await patchAPI(
      Action.ForumDetail.replace('<id>', String(id)),
      data
    );
    return response;
  }
  public async replyForum(id: number, data: FormData) {
    const response = await postAPI(
      Action.ReplyForum.replace('<id>', String(id)),
      data
    );
    return response;
  }
})();
