import { getAPI } from '@/store/api';
import { Action } from '@/store/api/actions';

export default new (class AlbumAPI {
  public async getAllFruitSizing(query = '') {
    const response = await getAPI(Action.FruitSizing, query);
    return response;
  }
  public async getFruitSizeDistributionGraph(query = '') {
    const response = await getAPI(Action.FruitSizeDistributionGraph, query);
    return response;
  }
  public async getFruitSizeDistributionGraphOld(query = '') {
    const response = await getAPI(Action.FruitSizeDistributionGraphOld, query);
    return response;
  }
  public async getRateOfFruitSizeIncreaseGraph(query = '') {
    const response = await getAPI(Action.RateOfFruitSizeIncreaseGraph, query);
    return response;
  }
})();
