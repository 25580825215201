import { getAPI, postAPI, deleteAPI, patchAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import { AddDiary } from '@/store/models/diary';

export default new (class DiaryAPI {
  public async getAllDiary(query = '') {
    const response = await getAPI(Action.Diary, query);
    return response;
  }
  public async getDiaryById(id: number) {
    const response = await getAPI(`${Action.Diary}/${id}`);
    return response;
  }

  public async createDiary(diary: FormData) {
    const response = await postAPI(Action.Diary, diary);
    return response;
  }

  public async updateDiary(id: number, data: FormData) {
    const response = await patchAPI(`${Action.Diary}/${id}`, data);
    return response;
  }

  public async deleteDiary(id: number) {
    const response = await deleteAPI(`${Action.Diary}/${id}`);
    return response;
  }
})();
