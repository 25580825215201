import { deleteAPI, getAPI, patchAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';

export default new (class BlockAPI {
  public async getBlogCategories() {
    const response = await getAPI(Action.BlogCategories);
    return response;
  }
  public async getBlogs(query = '') {
    const response = await getAPI(Action.Blogs, query);
    return response;
  }
  public async getMyBlogs(query = '') {
    const response = await getAPI(Action.MyBlogs, query);
    return response;
  }
  public async getBlogById(id: number) {
    const response = await getAPI(
      Action.BlogDetail.replace('<id>', String(id))
    );
    return response;
  }
  public async createBlog(data: FormData) {
    const response = await postAPI(Action.Blogs, data);
    return response;
  }
  public async updateBlog(id: number, data: FormData) {
    const response = await patchAPI(
      Action.BlogDetail.replace('<id>', String(id)),
      data
    );
    return response;
  }
  public async deleteBlog(id: number) {
    const response = await deleteAPI(
      Action.BlogDetail.replace('<id>', String(id))
    );
    return response;
  }
})();
