import { deleteAPI, getAPI, postAPI, putAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import {
  Crop,
  Block,
  AssignCrop,
  AssignCorrectionFactor,
  CropType,
  CropVariety
} from '@/store/models/block';

export default new (class BlockAPI {
  public async getSimpleBlocks(query = '') {
    const response = await getAPI(Action.SimpleBlocks, query);
    return response;
  }
  public async getAllMyBlocks(query = '') {
    const response = await getAPI(Action.Blocks, query);
    return response;
  }
  public async getAllMyBlocksWithFruitCounts(query = '') {
    const response = await getAPI(Action.BlocksWithFruitCounts, query);
    return response;
  }
  public async getAllMyBlocksWithOnlyFruitCounts(query = '') {
    const response = await getAPI(Action.BlocksWithOnlyFruitCounts, query);
    return response;
  }
  public async getAllMyBlocksWithAvgDM(query = '') {
    const response = await getAPI(Action.BlocksWithAvgDM, query);
    return response;
  }
  public async getAllMyBlocksWithFloweringCount(query = '') {
    const response = await getAPI(Action.BlocksWithFloweringCount, query);
    return response;
  }
  public async getAllMyBlocksWithFruitSize(query = '') {
    const response = await getAPI(Action.BlocksWithFruitSize, query);
    return response;
  }
  public async getAllMyBlocksSummary(query = '') {
    const response = await getAPI(Action.BlockSummary, query);
    return response;
  }
  public async getAllMyBlocksWithGeom(query = '') {
    const response = await getAPI(Action.GeomBlocks, query);
    return response;
  }
  public async getBlockById(id: number) {
    const response = await getAPI(`${Action.Blocks}/${id}`);
    return response;
  }
  public async getMapFeatureBlockById(id: number, query = '') {
    return await getAPI(
      `${Action.MapFeatureBlock.replace('{id}', String(id))}`,
      query
    );
  }

  public async importBlocks(data: FormData) {
    const response = await postAPI(Action.ImportBlocks, data);
    return response;
  }

  public async exportFarmBlocks(farm: number) {
    const response = await getAPI(
      Action.ExportFarmBlocks.replace('{farm_id}', String(farm))
    );
    return response;
  }

  public async exportBlock(id: number) {
    const response = await getAPI(
      Action.ExportBlock.replace('{block_id}', String(id))
    );
    return response;
  }

  public async exportBlocks(query = '') {
    const response = await getAPI(Action.ExportBlocks, query);
    return response;
  }

  public async createBlock(block: Block) {
    const response = await postAPI(Action.Blocks, block);
    return response;
  }

  public async updateBlock(block: Block) {
    const response = await putAPI(`${Action.Blocks}/${block.id}`, block);
    return response;
  }

  public async deleteBlock(id: number) {
    const response = await deleteAPI(`${Action.Blocks}/${id}`);
    return response;
  }
  public async getAllMyUniqueCrops(query = '') {
    const response = await getAPI(Action.UniqueCrops, query);
    return response;
  }

  public async getAllCrops(query = '') {
    const response = await getAPI(Action.Crops, query);
    return response;
  }

  public async getCrop(id: number) {
    const response = await getAPI(
      Action.CropDetail.replace('<id>', String(id))
    );
    return response;
  }

  public async createCrop(crop: Crop) {
    const response = await postAPI(Action.Crops, crop);
    return response;
  }

  public async updateCrop(crop: Crop) {
    const response = await putAPI(`${Action.Crops}/${crop.id}`, crop);
    return response;
  }

  public async deleteCrop(id: number) {
    const response = await deleteAPI(`${Action.Crops}/${id}`);
    return response;
  }

  public async getAllCropTypes(query = '') {
    const response = await getAPI(Action.CropTypes, query);
    return response;
  }

  public async getAllCropVarieties(query = '') {
    const response = await getAPI(Action.CropVarieties, query);
    return response;
  }

  public async assignCrop(assignCrop: AssignCrop) {
    const response = await putAPI(Action.AssignCrop, assignCrop);
    return response;
  }

  public async assignCorrectionFactor(
    assignCorrectionFactor: AssignCorrectionFactor
  ) {
    const response = await putAPI(
      Action.AssignCorrectionFactor,
      assignCorrectionFactor
    );
    return response;
  }
  public async batchUploadPackHouse(data: FormData) {
    const response = await postAPI(Action.PackHouseBatchUpload, data);
    return response;
  }

  public async getCropType(id: number) {
    const response = await getAPI(`${Action.CropTypes}/${id}`);
    return response;
  }

  public async createCropType(cropType: CropType) {
    const response = await postAPI(Action.CropTypes, cropType);
    return response;
  }

  public async updateCropType(cropType: CropType) {
    const response = await putAPI(
      `${Action.CropTypes}/${cropType.id}`,
      cropType
    );
    return response;
  }

  public async deleteCropType(id: number) {
    const response = await deleteAPI(`${Action.CropTypes}/${id}`);
    return response;
  }

  public async getCropVariety(id: number) {
    const response = await getAPI(`${Action.CropVarieties}/${id}`);
    return response;
  }

  public async createCropVariety(cropVariety: CropVariety) {
    const response = await postAPI(Action.CropVarieties, cropVariety);
    return response;
  }

  public async updateCropVariety(cropVariety: CropVariety) {
    const response = await putAPI(
      `${Action.CropVarieties}/${cropVariety.id}`,
      cropVariety
    );
    return response;
  }

  public async deleteCropVariety(id: number) {
    const response = await deleteAPI(`${Action.CropVarieties}/${id}`);
    return response;
  }
})();
