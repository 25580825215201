import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Action } from '@/store/api/actions';
import { JwtStore } from '@/store/modules';
import router from '@/router';

import { Notify, LoadingBar } from 'quasar';

let baseUrl = process.env.VUE_APP_API;
if (!baseUrl || baseUrl == '/') {
  baseUrl = '';
}

export const baseURL = baseUrl;

export const api = axios.create({
  baseURL: `${baseUrl}/${Action.API}`
});

// Make Axios play nice with Django CSRF
api.defaults.xsrfCookieName = 'csrftoken';
api.defaults.xsrfHeaderName = 'X-CSRFToken';

export function JwtExpired() {
  const exp = JwtStore.DecodedPayload.exp;
  const now = Math.floor(new Date().getTime() / 1000);
  if (exp <= now) {
    return true;
  }
  return false;
}

async function refreshing() {
  while (JwtStore.RefreshingToken) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
  return false;
}

let pendings: any = {};
let caches: any = {};
const cacheUtils = {
  getUniqueUrl: function(config: AxiosRequestConfig): string {
    // you can set the rule based on your own requirement
    return config.url + '&' + config.method;
  },
  clearAll: function() {
    pendings = {};
    caches = {};
  },
  isCached: function(config: AxiosRequestConfig) {
    const cacheExpireTime = 1000 * 5;
    const uniqueUrl = this.getUniqueUrl(config);
    const cache = caches[uniqueUrl];
    if (cache) {
      if (cache['setTime'] + cacheExpireTime > new Date().getTime()) {
        return true;
      }
    }
    return false;
  },
  isPending: function(config: AxiosRequestConfig) {
    const uniqueUrl = this.getUniqueUrl(config);
    if (!pendings[uniqueUrl]) {
      pendings[uniqueUrl] = [config];
      return false;
    } else {
      // console.log(`cache url: ${uniqueUrl}`);
      pendings[uniqueUrl].push(config);
      return true;
    }
  },
  setCachedResponse: function(
    config: AxiosRequestConfig,
    response: AxiosResponse
  ) {
    const uniqueUrl = this.getUniqueUrl(config);
    caches[uniqueUrl] = { response: response, setTime: new Date().getTime() };
    if (pendings[uniqueUrl]) {
      delete pendings[uniqueUrl];
      // pendings[uniqueUrl].forEach((configItem: any) => {
      //   configItem.isFinished = true;
      // });
    }
  },
  getError: function(config: AxiosRequestConfig) {
    const skipXHRError = new Error('skip') as any;
    skipXHRError.isSkipXHR = true;
    skipXHRError.requestConfig = config;
    return skipXHRError;
  },
  getCachedResponse: function(config: AxiosRequestConfig) {
    const uniqueUrl = this.getUniqueUrl(config);
    return caches[uniqueUrl]['response'];
  }
};

api.interceptors.request.use(
  async config => {
    if (config.url != `/${Action.RefreshToken}/` && JwtStore.RefreshingToken) {
      await refreshing();
    }
    if (
      config.url == `/${Action.ClearToken}/` ||
      config.url == `/${Action.Token}/`
    ) {
      cacheUtils.clearAll();
    }
    if (
      config.url != `/${Action.ClearToken}/` &&
      config.url != `/${Action.RefreshToken}/` &&
      config.url != `/${Action.Token}/` &&
      JwtStore.AccessToken &&
      JwtStore.AccessToken != ''
    ) {
      if (JwtExpired()) {
        JwtStore.refreshingToken();
        await JwtStore.refreshAccessToken();
      }
      if (config.headers) {
        if (JwtStore.AccessToken) {
          config.headers.Authorization = 'Bearer ' + JwtStore.AccessToken;
        } else {
          delete config.headers.Authorization;
          const error = cacheUtils.getError(config);
          throw error;
        }
      }
    } else {
      if (config.headers) {
        delete config.headers.Authorization;
      }
    }
    if (
      config.method?.toLowerCase() == 'get' &&
      !config.url?.includes(Action.Notifications)
    ) {
      if (cacheUtils.isCached(config)) {
        const error = cacheUtils.getError(config);
        throw error;
      }
      if (cacheUtils.isPending(config)) {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            // eslint-disable-next-line
            if (cacheUtils.isCached(config)) {
              clearInterval(interval);
              const error = cacheUtils.getError(config);
              reject(error);
            }
          }, 200);
        });
      } else {
        LoadingBar.start();
        LoadingBar.increment(0.5);
        // the head of cacheable requests queue, get the response by http request
        return config;
      }
    }

    return config;
  },
  error => {
    LoadingBar.stop();
    LoadingBar.increment(1);
    Notify.create({
      type: 'negative',
      message: error
    });
    return new Promise(error);
  }
);

api.interceptors.response.use(
  config => {
    LoadingBar.stop();
    LoadingBar.increment(1);
    cacheUtils.setCachedResponse(config.config, config);
    return config;
  },
  error => {
    LoadingBar.stop();
    LoadingBar.increment(1);
    if (error.isSkipXHR) {
      return cacheUtils.getCachedResponse(error.requestConfig);
    }

    /** Handler for logging out if refresh token is expired */
    if (error.response.config.url == `/${Action.RefreshToken}/`) {
      if (error.response.status == 401) {
        if (error.response.data.message != 'NoError') {
          JwtStore.clearJWT().then(() => {
            router.push({ name: 'LandingPage' });
            Notify.create({
              message: 'Session has been expired. Please login again.',
              type: 'negative',
              position: 'top-right'
            });
          });
          return new Promise(error);
        }
      }
    }

    /** Handler for refreshing access token */
    if (error.response.config.url != `/${Action.RefreshToken}/`) {
      if (
        (error.response.status == 403 || error.response.status == 401) &&
        error.response.data.message == 'Token is invalid or expired'
      ) {
        JwtStore.refreshingToken();
        JwtStore.clearJWT().then(() => {
          router.push({ name: 'LandingPage' });
          Notify.create({
            message: 'Session has been expired. Please login again.',
            type: 'negative',
            position: 'top-right'
          });
        });
        JwtStore.refreshAccessToken().then(() => {
          const config = error.response.config;
          if (JwtStore.AccessToken && JwtStore.AccessToken != '') {
            config.headers.Authorization = `Bearer ${JwtStore.AccessToken}`;
            api.request(config);
          }
          return config;
        });
        return new Promise(error.response.config);
      }
    }

    /** Handler for error response with and without message */
    if (error.response.data.message) {
      if (error.response.data.message != 'NoError') {
        Notify.create({
          message: error.response.data.message,
          type: 'negative',
          position: 'top'
        });
      }
    } else {
      Notify.create({
        message: 'Network Error',
        type: 'negative',
        position: 'top'
      });
    }
    return new Promise(error);
  }
);

// eslint-disable-next-line
export async function postAPI(
  url: string,
  // eslint-disable-next-line
  data: any,
  config?: AxiosRequestConfig
  // eslint-disable-next-line
): Promise<any> {
  return new Promise((resolve, reject) => {
    const apiResponse = api.post(`/${url}/`, data, config);
    apiResponse.then(
      result => {
        resolve(result.data);
      },
      error => {
        if (
          url == Action.RefreshToken &&
          (error.status_code == 400 || error.status_code == 401)
        ) {
          resolve({});
        }
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line
export async function putAPI(
  url: string,
  // eslint-disable-next-line
  data: any,
  config?: AxiosRequestConfig
  // eslint-disable-next-line
): Promise<any> {
  return new Promise((resolve, reject) => {
    const apiResponse = api.put(`/${url}/`, data, config);
    apiResponse.then(
      result => {
        resolve(result.data);
      },
      error => {
        if (
          url == Action.RefreshToken &&
          (error.status_code == 400 || error.status_code == 401)
        ) {
          resolve({});
        }
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line
export async function patchAPI(
  url: string,
  // eslint-disable-next-line
  data: any,
  config?: AxiosRequestConfig
  // eslint-disable-next-line
): Promise<any> {
  return new Promise((resolve, reject) => {
    const apiResponse = api.patch(`/${url}/`, data, config);
    apiResponse.then(
      result => {
        resolve(result.data);
      },
      error => {
        if (
          url == Action.RefreshToken &&
          (error.status_code == 400 || error.status_code == 401)
        ) {
          resolve({});
        }
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line
export async function deleteAPI(
  url: string,
  config?: AxiosRequestConfig
  // eslint-disable-next-line
): Promise<any> {
  return new Promise((resolve, reject) => {
    const apiResponse = api.delete(`/${url}/`, config);
    apiResponse.then(
      result => {
        resolve(result.data);
      },
      error => {
        if (
          url == Action.RefreshToken &&
          (error.status_code == 400 || error.status_code == 401)
        ) {
          resolve({});
        }
        reject(error);
      }
    );
  });
}

// eslint-disable-next-line
export function getAPI(
  url: string,
  query = '',
  config?: AxiosRequestConfig
): Promise<any> {
  return new Promise((resolve, reject) => {
    const apiResponse = api.get(`/${url}/${query}`, config);
    apiResponse.then(
      result => {
        resolve(result.data);
      },
      error => {
        reject(error);
      }
    );
  });
}
