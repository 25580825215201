import { getAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';

export default new (class NotificationAPI {
  public async getNotifications(query = '') {
    const response = await getAPI(Action.Notifications, query);
    return response;
  }
  public async getNotificationCount(query = '') {
    const response = await getAPI(Action.NotificationsCount, query);
    return response;
  }
  public async markNotificationAsRead(id: number) {
    const response = await postAPI(
      Action.MarkNotificationAsRead.replace('<id>', String(id)),
      {}
    );
    return response;
  }
  public async markNotificationAsUnRead(id: number) {
    const response = await postAPI(
      Action.MarkNotificationAsUnRead.replace('<id>', String(id)),
      {}
    );
    return response;
  }
  public async markAllNotificationAsRead() {
    const response = await postAPI(Action.MarkAllNotificationAsRead, {});
    return response;
  }
})();
