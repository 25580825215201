import { deleteAPI, getAPI, patchAPI, postAPI } from '@/store/api';
import { Action } from '@/store/api/actions';
import { Farm, FarmSensor } from '@/store/models/farm';

export default new (class FarmAPI {
  public async getAllMyFarms(query = '') {
    const response = await getAPI(Action.Farms, query);
    return response;
  }
  public async getFarmById(id: number) {
    const response = await getAPI(`${Action.Farms}/${id}`);
    return response;
  }
  public async getMyDefaultFarm() {
    const response = await getAPI(`${Action.MyDefaultFarm}`);
    return response;
  }

  public async createFarm(farm: Farm) {
    const response = await postAPI(Action.Farms, farm);
    return response;
  }

  public async updateFarm(farm: Farm) {
    const response = await patchAPI(`${Action.Farms}/${farm.id}`, farm);
    return response;
  }

  public async deleteFarm(id: number) {
    const response = await deleteAPI(`${Action.Farms}/${id}`);
    return response;
  }

  public async getAllFarmSensor(query = '') {
    const response = await getAPI(Action.FarmSensor, query);
    return response;
  }

  public async createFarmSensor(farmSensor: FarmSensor) {
    const response = await postAPI(Action.FarmSensor, farmSensor);
    return response;
  }
})();
